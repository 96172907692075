.locations-container {
  padding: 0 1px; /* Slight padding to prevent card shadow clipping */
}

.location-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03),
              0 2px 4px rgba(0, 0, 0, 0.03);
  transition: all 0.3s ease;
}

.location-card:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05),
              0 4px 8px rgba(0, 0, 0, 0.05);
}

.line-items-container {
  margin-top: 16px;
}
