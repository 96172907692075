/* Import Inspiration font */
@import url('https://fonts.googleapis.com/css2?family=Inspiration&display=swap');

.app-header {
  background: linear-gradient(to right, #1a1a1a 0%, #2d2d2d 100%);
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 48px !important;
  line-height: 48px;
  justify-content: space-between;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
}

/* Left section with logo */
.header-left {
  min-width: 200px;
  display: flex;
  align-items: center;
}

/* Logo styling */
.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 28px;
  width: auto;
  opacity: 0.95;
  transition: opacity 0.2s ease;
}

/* Center section with title */
.header-center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Specific to Planalyzer header */
.planalyzer-logo-text {
  color: #fff;
  font-size: 32px;
  font-family: 'Inspiration', cursive;
  letter-spacing: 1px;
  line-height: 48px;
  text-align: center;
}

/* Right section with avatar */
.header-right {
  min-width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user-avatar {
  cursor: pointer;
}

.avatar-icon {
  background: rgba(255, 255, 255, 0.15);
  border: none;
  transition: all 0.2s ease;
  opacity: 0.85;
}

.avatar-icon:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.25);
}
