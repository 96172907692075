.location-select .ant-select-selector {
  border-radius: 4px;
}

.location-select .ant-select-arrow {
  display: none;
}

.location-select .ant-select-selection-item {
  font-weight: normal;
}

.location-select .ant-select-item-option-disabled {
  color: #d9d9d9 !important;
  cursor: not-allowed;
}

.location-select .ant-select-item-option-disabled.option-divider {
  cursor: default;
  text-align: center;
  color: #d9d9d9;
}

.location-select .ant-select-item-option-disabled .ant-select-item-option-content {
  color: #d9d9d9;
}

.location-select .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: normal;
  background-color: #e6f7ff;
}

.location-select .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
