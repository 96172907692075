/* Modern gradient background with animation */
@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Uncomment one of these gorgeous gradient options! */

/* Option 1: Luxurious Black & Gold 
.app-header {
  background: linear-gradient(135deg, #1a1a1a 0%, #1f1f1f 49%, #d4af37 100%);
  background-size: 200% 200%;
  animation: gradientBG 15s ease infinite;
}
*/
/* Option 2: Sunset Fox (uncomment to use) 
.app-header {
  background: linear-gradient(135deg, #000000 0%, #cc7722 50%, #ff4500 100%);
  background-size: 200% 200%;
  animation: gradientBG 15s ease infinite;
}


/* Option 3: Royal Gold (uncomment to use)
.app-header {
  background: linear-gradient(135deg, #000000 0%, #b8860b 50%, #ffd700 100%);
  background-size: 200% 200%;
  animation: gradientBG 15s ease infinite;
}
*/

/* Option 4: Modern Fox (uncomment to use)*/
/*.app-header {
  background: linear-gradient(135deg, #1a1a1a 0%, #ff8c00 50%, #ffd700 100%);
  background-size: 200% 200%;
  animation: gradientBG 15s ease infinite;
}
*/

/* Import Barriecito font */
@import url('https://fonts.googleapis.com/css2?family=Barriecito&display=swap');

.app-header {
  background: linear-gradient(to right, #001233 0%, #001947 100%);
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 48px !important;
  line-height: 48px;
  justify-content: space-between;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
}

/* Left section with menu and logo */
.header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Menu icon styling */
.menu-icon {
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.2s ease;
  opacity: 0.85;
}

.menu-icon:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

.menu-icon.open {
  background: rgba(255, 255, 255, 0.15);
}

/* Logo styling */
.logo-link {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.logo-image {
  height: 28px;
  width: auto;
  opacity: 0.95;
  transition: opacity 0.2s ease;
}

.logo-link:hover .logo-image {
  opacity: 1;
}

.logo-text {
  color: #fff;
  font-size: 32px;
  font-family: 'Barriecito', cursive;
  letter-spacing: 1px;
  line-height: 48px;
  transform: translateY(-1px);
}

/* Right section with avatar */
.header-right {
  min-width: 200px;
  display: flex;
  justify-content: flex-end;
}

.user-avatar {
  cursor: pointer;
}

.avatar-icon {
  background: rgba(255, 255, 255, 0.15);
  border: none;
  transition: all 0.2s ease;
  opacity: 0.85;
}

.avatar-icon:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.25);
}

/* Dropdown menu customization */
:where(.css-dev-only-do-not-override-xu9wm8).ant-dropdown .ant-dropdown-menu {
  background: #002050;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-dropdown .ant-dropdown-menu-item {
  color: rgba(255, 255, 255, 0.85);
  transition: all 0.2s ease;
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-dropdown .ant-dropdown-menu-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-dropdown .ant-dropdown-menu-item .anticon {
  color: rgba(255, 255, 255, 0.65);
}

.bug-submit-button {
  margin-right: 16px;
  display: flex;
  align-items: center;
  color: white !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  height: 32px;
  align-self: center;
}

.bug-submit-button:hover {
  background: rgba(255, 255, 255, 0.2) !important;
  border-color: rgba(255, 255, 255, 0.25) !important;
}

.bug-submit-button .anticon {
  margin-right: 8px;
  color: white;
}
